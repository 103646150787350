<template>
  <section class="home">
    <div class="container">
      <!-- <img class="home-img" width="800"  src="https://static.komservice.net/img/komservicenet/slide_gpon.jpeg" alt="кабель для підключення оптоволоконного з'єднання"> -->
      <!-- <ul class="home-list">
        <li class="home-list-item" v-for="item in mainList" :key="item.id">{{item.value}}</li>
      </ul> 
      <ul class="home-list">
        <li class="home-list-item" >
           Шановні абоненти мережі «Ком-сервіс»!!!
З 1 березня 2021 року відділ обслуговування абонентів переїзджає за адресою: вул. Гетьмана Виговського, 44! (Будинок, де знаходиться магазин Троянда)  Усі технічні спеціалісти, підтримка абонентів та бухгалтерія буде працювати за зазначеною адресою та за звичними Вам телефонами: 063-618-12-38, 067-275-10-71! Просимо вибачення за незручності!
        </li>
      </ul> -->
    </div>
  </section>
</template>


<script>
  export default {
    data: function () {
      return {
        mainList: [
          { id: '1', value: 'Чотири якісні послуги із одних рук: Інтернет, Телебачення, IPTV, Сервіс;' },
          { id: '2', value: 'Тарифні пакети з ГАРАНТОВАНОЮ швидкістю за доступною ціною;' },
          { id: '3', value: 'Професійна консультація та технічна підтримка щоденно з 9-00 до 21-00;' },
          { id: '4', value: 'Висока надійність і стабільність зв’язку, завдяки продуманній топології мережі;' },
          { id: '5', value: 'Швидке реагування монтажної бригади на виклики;' },
          { id: '6', value: 'Безкоштовне налаштування мережевого обладнання;' },
          { id: '7', value: 'Різноманіття форм оплати.' },
        ],

      }
    }
  }

</script>
